<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>
            <strong>E Experience View</strong>
            <back-to-list></back-to-list>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span
                @click="selected = 'Details'"
                v-if="checkPermission('list-e-experience')"
            >
              <CTab active>
                <template slot="title">
                  {{ $lang.eexperience.tab.details }}
                </template>
              </CTab>
            </span>
            <span
                @click="selected = 'ExperienceMedia'"
                v-if="checkPermission('list-e-experience')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.eexperience.tab.media }}
                </template>
              </CTab>
            </span>
            <span
                @click="selected = 'ExperienceReport'"
                v-if="checkPermission('list-e-experience')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.eexperience.tab.report }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component
              :is="selected"
              style="margin-top: 20px"
              v-bind:postId="currentId"
              v-bind:profileId="profileId"
              @changeSelected="getTab($event)"
          ></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {Mixin} from "/src/mixins/index.js";
import Details from "./tab/Details";
import ExperienceMedia from "./tab/Media";
import ExperienceReport from "./tab/Reports";
import BackToList from "../backToList.vue";

export default {
  name: "ExperienceTabs",
  mixins: [Mixin],
  components: {
    Details,
    ExperienceMedia,
    ExperienceReport,
    BackToList,
  },
  data() {
    return {
      userId: "",
      selected: "Details",
      currentId: "",
      profileId: "",
      backToListFlag: false,
    };
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.userId = this.$route.params.ids;
    self.currentId = id;

    const testString = this.$router.history.current.path;
    let pathString = this.getStringBetween(testString, "all", "view");
    if (pathString !== 0) {
      // let entity = pathString.replace("/", "");
      //  localStorage.setItem("activeTab", entity);
      this.backToListFlag = this.getStringBetween(testString, "all", "view") !== 0;
    }
  },
  methods: {
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: #ff0000;
}

.hasError label {
  color: red;
}
</style>

