<template>
  <div class="detail-div">
    <CRow>


      <div>
        <CModal
            :title="
            submitType === 'Update'
              ? $lang.eexperience.crud.edit
              : $lang.common.general.reason
          "
            size="lg"
            :show.sync="largeModal"
            :no-close-on-backdrop="true"
            color="primary"
        >
          <CCardBody>
            <CRow>
              <CCol md="12">
                <c-textarea
                    v-model="eexperience.reason"
                    :rows="5"
                    :cols="10"
                    placeholder="Add Reason"
                ></c-textarea>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <CButton
                      color="primary"
                      v-c-tooltip="$lang.post.crud.post_status"
                      :disabled="submitted"
                      v-on:click="blockPost(statusId)"
                  >
                    Submit
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <template #footer style="display: none">
            <CButton
                @click="darkModal = false"
                color="danger"
                style="display: none"
            >Discard
            </CButton
            >
            <CButton
                @click="darkModal = false"
                color="success"
                style="display: none"
            >Accept
            </CButton
            >
          </template>
        </CModal>
      </div>

      <CCol md="12" class="align-self-center">
        <CRow>

          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.user }}</p>
            <p class="custome-detail">{{ eexperience.userName }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.companyName }}</p>
            <p class="custome-detail">{{ receivedData.companyName }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.companyWebsite }}</p>
            <p class="custome-detail">{{ receivedData.companyWebsite }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.empId }}</p>
            <p class="custome-detail">{{ receivedData.empId }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.firstName }}</p>
            <p class="custome-detail">{{ receivedData.firstName }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.lastName }}</p>
            <p class="custome-detail">{{ receivedData.lastName }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.empId }}</p>
            <p class="custome-detail">{{ receivedData.empId }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.jobTitle }}</p>
            <p class="custome-detail">{{ receivedData.jobTitle }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.lastDateOfWorking }}</p>
            <p class="custome-detail">{{ receivedData.lastDateOfWorking }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.panNumber }}</p>
            <p class="custome-detail">{{ receivedData.panNumber }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.remarks }}</p>
            <p class="custome-detail">{{ receivedData.remarks }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.country }}</p>
            <p class="custome-detail">{{ eexperience.country }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.city }}</p>
            <p class="custome-detail">{{ eexperience.city }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.industry }}</p>
            <p class="custome-detail">{{ eexperience.country }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.eexperience.detail.reasons }}</p>
            <p class="custome-detail">
              <template v-for="(reason) in receivedData.reasons">
                <span class="item">{{ reason.title }}<span class="comma">,</span>  </span>
              </template>
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="12">
            <p class="custome-labal">
              {{ $lang.eexperience.detail.description }}
            </p>
            <p class="custome-detail">
              {{ receivedData.description }}
            </p>
          </CCol>
        </CRow>

        <EntityTimeStamp :createdAt="receivedData.createdAt" :updatedAt="receivedData.updatedAt"></EntityTimeStamp>
        <CRow v-show="eexperience.socialLength">
          <CCol sm="6" md="4" lg="12">
            <p><b>Social Links</b></p>
            <table class="table table-striped table-bordered table-hover">
              <thead>
              <th>Plateform</th>
              <th>Link</th>
              </thead>
              <tbody>
              <tr
                  v-for="(links) in receivedData.socialLinks"
                  :key="links.id"
                  class="doc-box">
                <td>{{ links.platform }}</td>
                <td><span v-if="links.link">{{ links.link }}</span>
                  <span v-else> Not added yet</span>
                </td>
              </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>

        <CRow v-show="receivedData.blockedReason">
          <CCol sm="12" align="left">
            <strong>Blocked Reason: </strong> {{ receivedData.blockedReason }}
          </CCol>
        </CRow>

        <CRow v-if="!receivedData.blockedReason">
          <CCol sm="12" align="left">
            <CButton
                :color="statusColor"
                v-c-tooltip="statusLabel"
                v-on:click="beforeBlocked()"
            >
              {{ statusLabel }}
            </CButton>
          </CCol>
        </CRow>


      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {EExperience} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import VueNotifications from "vue-notifications";
import EntityTimeStamp from "/src/components/EntityTimeStamp";

Vue.use(VueNotifications);
Vue.use(VueForm);

export default {
  name: "DetailItem",
  mixins: [Mixin],
  components: {
    EntityTimeStamp,
  },
  data() {
    return {
      statusId: "",
      statusLabel: "Block",
      statusColor: "danger",
      submitted: false,
      largeModal: false,
      submitType: "",
      statusOption: [],
      showTax: true,
      err_msg: "",
      dismissCountDown: 0,
      receivedData: [],
      self: this,
      module: EExperience,
      eexperience: {
        userName: "",
        country: "",
        city: "",
        industry: "",
        reasons: "",
        socialLength: "",
        isActive: "",
        reason: "",
        statusId: "",
      },
    };
  },
  mounted() {
    let self = this;

    self.statusOption.push(
        {value: "1", label: "Active"},
        {value: "0", label: "InActive"},
        {value: "2", label: "Block"}
    );

    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    axios
        .get(this.viewUrlApi(this.module, id))
        .then((response) => {
          if (response.data) {
            let responseData = response.data.data;
            self.receivedData = responseData;
            self.eexperience.isActive = responseData.isActive;
            self.eexperience.userName = responseData?.userDetail?.name;
            self.eexperience.country = responseData?.country?.title;
            self.eexperience.city = responseData?.city?.title;
            self.eexperience.industry = responseData?.industry?.title;
            self.eexperience.reasons = responseData?.reasons?.null;
            self.eexperience.socialLength = responseData?.socialLinks?.null;


            if (responseData.isActive === 1) {
              self.eexperience.isStatus = 'Open';
              self.statusLabel = this.getStatusLabel(2);
              self.statusId = "2";
              self.statusColor = "danger";
            } else {
              self.statusId = "1";
              self.statusLabel = this.getStatusLabel(1);
              self.statusColor = "success";
            }

            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    getStatusLabel(getStatus) {
      let valObj = " ";
      valObj = this.statusOption.filter(function (elem) {
        if (elem.value == getStatus) return elem.value;
      });
      if (valObj.length > 0) return valObj[0].label;
    },
    beforeBlocked() {
      let self = this;
      self.eexperience.reason = "";
      if (self.eexperience.isActive === 1) {
        this.largeModal = true;
      } else {
        this.blockPost(self.statusId);
      }
    },
    blockPost(statusId) {
      let self = this;
      self.submitted = true; //Enable Button
      if (self.eexperience.isActive === 1) {
        if (self.eexperience.reason === "") {
          self.submitted = false; //Enable Button
          return false;
        }
        this.$swal({
          title: this.$lang.common.status.want_to_block,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#e55353",
          confirmButtonText: this.$lang.common.status.yes_block,
          cancelButtonText: this.$lang.common.status.cancel,
        }).then((result) => {
          if (result.value) {
            const id = this.$route.params.id;

            setTimeout(() => {
              let self = this;
              const postData = {
                status: statusId,
                reason: self.eexperience.reason,
              };
              let status, button;
              if (self.statusId === "1") {
                self.statusId = "2";
                self.statusColor = "danger";
                status = this.getStatusLabel(1);
                button = "Block";
              } else {
                self.statusId = "1";
                self.statusColor = "success";
                status = "Blocked";
                button = this.getStatusLabel(1);
              }
              axios
                  .post('e_experience/update-status/' + id, postData)
                  .then(function (response) {
                    if (response.data.code === 200) {
                      self.eexperience.isStatus = status;
                      self.statusLabel = button;
                      self.largeModal = false;
                      self.submitted = false; //Enable Button
                      self.$router.go();
                    } else {
                      self.err_msg = response.data.message;
                      self.dismissCountDown = 10;
                    }
                  })
                  .catch(function (error) {
                    self.submitted = false; //Enable Button
                  });
            }, 500);
          }
        });
      } else {
        let self = this;
        const id = this.$route.params.id;
        const postData = {
          status: statusId,
          reason: null,
        };
        let status, button;
        if (self.statusId === "1") {
          self.statusId = "2";
          self.statusColor = "danger";
          status = this.getStatusLabel(1);
          button = "Block";
        } else {
          self.statusId = "1";
          self.statusColor = "success";
          status = "Blocked";
          button = this.getStatusLabel(1);
        }
        axios
            .post(this.statusUrlWeb(this.module, id), postData)
            .then(function (response) {
              if (response.data.code === 200) {
                self.eexperience.isStatus = status;
                self.statusLabel = button;
                self.largeModal = false;
                self.submitted = false; //Enable Button
                self.$router.go();
              } else {
                self.err_msg = response.data.message;
                self.dismissCountDown = 10;
              }
            })
            .catch(function (error) {
              self.submitted = false; //Enable Button
            });
      }
    },


  },
};
</script>
