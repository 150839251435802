<template>
  <CRow>


    <div>
      <CModal
          title="View Report"
          size="lg"
          :show.sync="largeModal"
          :no-close-on-backdrop="true"
          color="primary"
      >
        <div class="form-group" :class="{ 'form-group--error': '' }">
          <label class="form__label">
            {{ $lang.eexperience.report.table.reason }} :
          </label>
          <div>
            {{ receivedData.reason }}
          </div>
        </div>
        <div class="form-group">
          <label class="form__label"
          >{{ $lang.eexperience.report.table.description }} :
          </label>
          <div>
            {{ receivedData.description }}
          </div>
        </div>
        <template #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>
    </div>


    <CCol col="12">
      <div class="row">
        <CCol sm="6" class="align-self-center"></CCol>
        <CCol sm="6" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.export.pdf"
                  v-on:click="
                      exports(self, 'pdf', module, 'EExperience Report', self.editId)
                    "
              >
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv
                  class="btn btn-success"
                  v-c-tooltip="$lang.buttons.export.excel"
                  :json-data="json_data"
                  :labels="json_label"
                  :csv-title="json_title"
              >
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
          </div>
        </CCol>
      </div>
      <CAlert
          color="success"
          closeButton
          :show.sync="dismissCountDownS"
          fade
      >{{ alertMessage }}
      </CAlert
      >
      <div id="people">
        <v-server-table
            :columns="columns"
            :url="listUrl"
            :options="options"
            ref="myTable"
            @loaded="
                exports(self, 'Excel', module, 'EExperience Report', self.editId)
              "
        >
          <template #actions="data">
            <CButton
                color="primary"
                v-c-tooltip="$lang.post_report.crud.view"
                v-on:click="viewReport(data.row.id)"
            >
              <i class="fas fa-eye"></i>
            </CButton>
            <!--<CButton color="success" v-c-tooltip="$lang.buttons.general.crud.edit"  v-on:click="editTableRow(data.row.id)"><i class="fas fa-pencil-alt"></i></CButton>-->
          </template>
        </v-server-table>
      </div>

    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {EExperienceReport} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "ExperienceReport",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      reportId: "",
      largeModal: false,
      ViewResponse: "",
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      receivedData: [],
      activePage: 1,
      listUrl: "",
      module: EExperienceReport,
      experience_report: {
        reason: "",
        description: "",
        isActive: "",
      },

      columns: ["reason", "username", "actions"],
      data: [],
      options: {
        headings: {
          reason: this.$lang.eexperience.report.table.reason,
          username: this.$lang.eexperience.report.table.username,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },

  validations: {
    experience_report: {
      reason: {
        required,
      },
    },
  },
  props: ["postId"],
  created() {
    let self = this;
    self.editId = this.postId;
    self.listUrl = "/e_experience/list-report/" + self.editId;
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.editId = this.postId; // from tab using props
    // self.listUrl = this.listUrl + self.editId;

    //self.data = this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });

  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    viewReport(item) {
      let self = this;
      self.reportId = item;
      axios
          .get(this.viewUrlApi(this.module, self.reportId), self.model)
          .then(function (response) {
            if (response) {
              self.receivedData = response.data.data;
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },

  },
};
</script>
